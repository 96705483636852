import { useEffect, useState, useRef } from "react";
export default function About() {
  const [age, setAge] = useState(null);
  const marqueeRef = useRef(null);

  useEffect(() => {
    const birthday = new Date("1998-09-07");
    const today = new Date();
    const diff = Math.abs(today - birthday);
    const ageInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    setAge(ageInYears);
  }, []);

  useEffect(() => {
    const marqueeContent = marqueeRef.current;
    const handleMouseOver = () => {
      marqueeContent.classList.add("paused");
    };
    const handleMouseOut = () => {
      marqueeContent.classList.remove("paused");
    };
    marqueeContent.addEventListener("mouseover", handleMouseOver);
    marqueeContent.addEventListener("mouseout", handleMouseOut);
    return () => {
      marqueeContent.removeEventListener("mouseover", handleMouseOver);
      marqueeContent.removeEventListener("mouseout", handleMouseOut);
    };
  }, []);
  const imgCarousel = [
    {
      id: 1,
      name: "HTML5",
      image: require("../assets/img/programming-logo/html5.png"),
    },
    {
      id: 2,
      name: "Cascading Style Sheet",
      image: require("../assets/img/programming-logo/cssLogo.png"),
    },
    {
      id: 3,
      name: "Java Script",
      image: require("../assets/img/programming-logo/JavaScript-logo.png"),
    },
    {
      id: 4,
      name: "React",
      image: require("../assets/img/programming-logo/react.png"),
    },
    {
      id: 5,
      name: "Laravel",
      image: require("../assets/img/programming-logo/laravel.png"),
    },
    {
      id: 6,
      name: "MySql",
      image: require("../assets/img/programming-logo/php.png"),
    },
    {
      id: 7,
      name: "Microsoft SQL Server",
      image: require("../assets/img/programming-logo/sqlServer.png"),
    },
    {
      id: 8,
      name: "Postgre Sql",
      image: require("../assets/img/programming-logo/postgreSql.png"),
    },
    {
      id: 9,
      name: "Golang",
      image: require("../assets/img/programming-logo/Go-Logo.png"),
    },
    {
      id: 10,
      name: "NodeJS",
      image: require("../assets/img/programming-logo/nodejs.png"),
    },
    {
      id: 11,
      name: "VueJS",
      image: require("../assets/img/programming-logo/vuejs.png"),
    },
    {
      id: 12,
      name: "C++",
      image: require("../assets/img/programming-logo/cpplus-logo.png"),
    },
    {
      id: 13,
      name: "Bootstrap",
      image: require("../assets/img/programming-logo/bootstrap-logo.png"),
    },
    {
      id: 13,
      name: "jQuery",
      image: require("../assets/img/programming-logo/jquery-logo.png"),
    },
    {
      id: 14,
      name: "Python",
      image: require("../assets/img/programming-logo/python-logo.png"),
    },
  ];
  return (
    <div className="about">
      <section id="about" className="about section">
        <div className="container section-title" data-aos="fade-up">
          <h2>About</h2>
          <p>
            Hi, I'm Rimba Sahara, a passionate and dedicated web developer
            committed to turning creative ideas into functional and
            user-friendly digital solutions. I hold a Bachelor's Degree in
            Computer Science from Binus University. I still learning and focuses
            on Software Developers role, especially full stack javascript
            developers, so i can fulfill both the backend and the frontend.
          </p>
        </div>

        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-4 justify-content-center">
            <div className="col-sm-4 text-center">
              <img
                src={require("../assets/img/profile-img.png")}
                className="img-fluid about-img rounded"
                alt=""
              />
            </div>
            <div className="col-lg-8 content">
              <h2>Web Developer &amp; UI Desginer.</h2>
              <p className="fst-italic py-1"></p>
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Birthday:</strong> <span>07 September 1998</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Website:</strong>{" "}
                      <a
                        href="https://rimbashr.my.id"
                        style={{ color: "black" }}
                      >
                        <span>rimbashr.my.id</span>
                      </a>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Phone:</strong> <span>+62 ********8981</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>City:</strong> <span>Jakarta, IDN</span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Age:</strong>{" "}
                      <span title="Calculated based on birthdate">{age}</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Degree:</strong> <span>Bachelor I</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Email:</strong>{" "}
                      <span>rimbasahara76@gmail.com</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Freelance:</strong> <span>Available</span>
                    </li>
                  </ul>
                </div>
              </div>
              <h2 style={{ textAlign: "center" }}>
                Programming & Database Skill.
              </h2>
              <div className="marquee">
                <div className="marquee-content" ref={marqueeRef}>
                  {imgCarousel.map((item) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          display: "inline-block",
                          margin: "0 10px",
                        }}
                      >
                        <img
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "contain",
                          }}
                          src={item.image}
                          alt="Skill List"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
