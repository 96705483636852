import { useState } from "react";
import axios from "axios";

export const useForm = (initialState, submitCallback) => {
  const [formData, setFormData] = useState(initialState);
  const [status, setStatus] = useState({
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api-portofolio.rimbashr.my.id/api/send",
        formData
      );
      setStatus({
        message: response.data.message,
        type: "success",
      });
      setLoading(false);
      submitCallback();
    } catch (error) {
      setStatus({
        message: "Failed to send email.",
        type: "danger",
      });
      setLoading(false);
    }

    setFormData(initialState);
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    status,
    loading,
  };
};
