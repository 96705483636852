import GLightbox from "glightbox";
import React, { useEffect } from "react";

export default function Portofolio() {
  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox",
    });
    return () => {
      lightbox.destroy();
    };
  }, []);

  const dataPorto = [
    {
      id: 1,
      name: "Bookshelf App",
      detail: "Book Organisation Application",
      image:
        "https://images.unsplash.com/photo-1676487093282-f6a47e02848f?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      preview: require("../assets/img/portfolio/bookshelf-app.jpg"),
      link: "https://rimbasahara.github.io/Bookshelf-App/",
    },
    {
      id: 2,
      name: "Shayna",
      detail: "Online Shop Website Develop with VueJS",
      image:
        "https://images.unsplash.com/photo-1700190827565-7b6247de4838?q=80&w=2060&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      preview: require("../assets/img/portfolio/shayna.jpg"),
      link: "https://onlineshopone.rimbashr.my.id/#/",
    },
    {
      id: 3,
      name: "Shayna Back End",
      detail: "Admin Shayna Develop with Laravel",
      image:
        "https://plus.unsplash.com/premium_photo-1678567672060-fb4eb9e6a004?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      preview: require("../assets/img/portfolio/shayna-backoffice.jpg"),
      link: "https://admin-onlineshopone.rimbashr.my.id",
    },
  ];

  return (
    <section id="portfolio" className="portfolio section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Portfolio</h2>
        <p>
          Here are some of the projects I've done & working on, nothing fancy{" "}
          <strong>yet </strong>
          but it's okay right? :)
        </p>
      </div>

      <div className="container">
        <div
          className="isotope-layout"
          data-default-filter="*"
          data-layout="masonry"
          data-sort="original-order"
        >
          <ul
            className="portfolio-filters isotope-filters"
            data-aos="fade-up"
            data-aos-delay="100"
          />
          <div
            className="row gy-4 isotope-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {dataPorto.map((item) => {
              return (
                <div
                  key={item.id}
                  className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app"
                >
                  <img src={item.image} className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>{item.name}</h4>
                    <p>{item.detail}</p>
                    <a
                      href={item.preview}
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-zoom-in"></i>
                    </a>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="More Details"
                      className="details-link"
                    >
                      <i className="bi bi-link-45deg"></i>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
