import { TypeAnimation } from "react-type-animation";

export default function Hero() {
  return (
    <section id="hero" className="hero section light-background">
      <img src={require("../assets/img/hero-bg.jpg")} alt="" />
      <div className="container" data-aos="zoom-out">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <h2>Rimba Sahara</h2>
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially
                "I'm Web Developer",
                1000,
                "I'm UI Desginer",
                1000,
                "I'm Database Administrator",
                1000,
                "I'm ERP Consultant",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: "2em", display: "inline-block" }}
              repeat={Infinity}
            />
            <div className="social-links">
              <a
                href="https://github.com/rimbasahara/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-github"></i>
              </a>
              <a
                href="https://www.instagram.com/rimbaashr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/rimba-s-862050196/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
