import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useForm } from "../assets/js/useform";

export default function Contact() {
  const initialState = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const [showModal, setShowModal] = useState(false);

  const { formData, handleChange, handleSubmit, status, loading } = useForm(
    initialState,
    () => setShowModal(true)
  );

  const handleClose = () => setShowModal(false);

  return (
    <div className="container">
      <section id="contact" className="contact section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Contact</h2>
          <p>
            I look forward to the opportunity and experience to suit your
            project needs. Let's get in touch.
          </p>
        </div>

        <div className="container" data-aos="fade" data-aos-delay="100">
          <div className="row gy-4">
            <div className="col-lg-4">
              <div
                className="info-item d-flex"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>Address</h3>
                  <p>
                    West Jakarta City, Special Capital Region of Jakarta, ID
                  </p>
                </div>
              </div>

              <div
                className="info-item d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <i className="bi bi-linkedin flex-shrink-0"></i>
                <div>
                  <h3>Approcah Me</h3>
                  <p>Rimba Sahara</p>
                </div>
              </div>

              <div
                className="info-item d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>Email Me</h3>
                  <p>rimbasahara76@gmail.com</p>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <form
                onSubmit={handleSubmit}
                method="post"
                className="php-email-form"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required=""
                      value={formData.name}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required=""
                      value={formData.email}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required=""
                      value={formData.subject}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required=""
                      value={formData.message}
                      onChange={handleChange}
                      disabled={loading}
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="danger"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Send Message
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your message has been sent. Thank you!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
