import { useRef, useEffect } from "react";

export default function Header() {
  const headerToggleBtnRef = useRef(null);
  const navmenuRef = useRef(null);

  function headerToggle() {
    document.querySelector("#header").classList.toggle("header-show");
    headerToggleBtnRef.current.classList.toggle("bi-list");
    headerToggleBtnRef.current.classList.toggle("bi-x");
  }

  useEffect(() => {
    if (headerToggleBtnRef.current) {
      headerToggleBtnRef.current.addEventListener("click", headerToggle);
    }
    return () => {
      if (headerToggleBtnRef.current) {
        headerToggleBtnRef.current.removeEventListener("click", headerToggle);
      }
    };
  }, []);

  function navmenuScrollspy() {
    const navmenulinks = navmenuRef.current.querySelectorAll("a");
    navmenulinks.forEach((navmenulink) => {
      if (!navmenulink.hash) return;
      let section = document.querySelector(navmenulink.hash);
      if (!section) return;
      let position = window.scrollY + 200;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navmenuRef.current
          .querySelectorAll("a.active")
          .forEach((link) => link.classList.remove("active"));
        navmenulink.classList.add("active");
      } else {
        navmenulink.classList.remove("active");
      }
    });
  }

  useEffect(() => {
    window.addEventListener("load", navmenuScrollspy);
    window.addEventListener("scroll", navmenuScrollspy);
    return () => {
      window.removeEventListener("load", navmenuScrollspy);
      window.removeEventListener("scroll", navmenuScrollspy);
    };
  }, []);

  return (
    <header
      id="header"
      className="header d-flex flex-column justify-content-center"
    >
      <i
        ref={headerToggleBtnRef}
        className="header-toggle d-xl-none bi bi-list"
      ></i>
      <nav id="navmenu" ref={navmenuRef} className="navmenu">
        <ul>
          <li>
            <a href="#" className="">
              <i className="bi bi-house navicon"></i>
              <span>Home</span>
            </a>
          </li>
          <li>
            <a href="#about">
              <i className="bi bi-person navicon"></i>
              <span>About</span>
            </a>
          </li>
          <li>
            <a href="#resume">
              <i className="bi bi-file-earmark-text navicon"></i>
              <span>Resume</span>
            </a>
          </li>
          <li>
            <a href="#portfolio">
              <i className="bi bi-images navicon"></i>
              <span>Portfolio</span>
            </a>
          </li>
          <li>
            <a href="#contact">
              <i className="bi bi-envelope navicon"></i>
              <span>Contact</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
