export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div id="footer" className="footer position-relative light-background">
      <div className="container">
        <h3 className="sitename">Rimba Sahara</h3>
        <p>
          Thank you for visiting and viewing my portfolio website. Hopefully we
          can discuss further.
        </p>
        <div className="social-links d-flex justify-content-center">
          <a
            href="https://github.com/rimbasahara/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-github"></i>
          </a>
          <a
            href="https://www.instagram.com/rimbaashr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/rimba-s-862050196/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
        <div className="container">
          <div className="copyright">
            <span>Copyright&copy;</span>{" "}
            <strong className="px-1 sitename">{currentYear}</strong>{" "}
            <span>Rimba Sahara. All Rights Reserved</span>
          </div>
          <div className="credits">
            Develop with <a href="https://react.dev">ReactJS</a>
          </div>
        </div>
      </div>
    </div>
  );
}
