export default function Resume() {
  return (
    <section id="resume" className="resume section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Resume</h2>
        <p>Here's my resume. all about my career journey :')</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className="resume-title">Sumary</h3>

            <div className="resume-item pb-0">
              <h4>Rimba Sahara</h4>
              <p>
                <em>
                  I am an honest and disciplined individual with strong
                  collaboration skills, able to work under pressure with great
                  flexibility. I always prioritise good ethics and morals, have
                  sharp analytical and decision-making skills, as well as
                  expertise in solving problems and providing guidance to the
                  team.
                </em>
              </p>
              <ul>
                <li>West Jakarta City, Special Capital Region of Jakarta</li>
                <li>Indonesia "Native" & English "Advanced"</li>
                <li>rimbasahara76@gmail.com</li>
              </ul>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
              <h4>Bachelor of Information Systems - S1</h4>
              <h5>February 2022 - July 2024</h5>
              <p>
                <em>Binus University, Jakarta, ID</em>
              </p>
            </div>

            <div className="resume-item">
              <h4>Diploma of Computer Technology - D3</h4>
              <h5>August 2016 - August 2019</h5>
              <p>
                <em>Telkom University, Bandung, ID</em>
              </p>
            </div>
            <h3 className="resume-title">Internship Experience</h3>
            <div className="resume-item">
              <h4>Faculty of Applied Sciences</h4>
              <h5>Assistant Practicum - Assistant and Coordinator</h5>
              <h6>December 2017 - April 2019</h6>
              <p>
                <em>Bandung, West Java, ID</em>
              </p>
              <ul>
                <li>Database</li>
                <li>Digital System</li>
                <li>Shell Programming</li>
                <li>Computer Network System</li>
                <li>Network Service</li>
                <li>Interfaces, peripherals, and communications</li>
              </ul>
            </div>
            <div className="resume-item">
              <h4>Embedded and Network Systems Laboratory</h4>
              <h5>Laboratory Assistant</h5> <h6>January 2019 to April 2019</h6>
              <p>
                <em>Bandung, West Java, ID</em>
              </p>
              <ul>
                <li>
                  Build, operate, and mai ntenance network around the ENS
                  Laboratory
                </li>
                <li>Making lecture partcicum materials and modules</li>
                <li>Lectured, supervised and guided partcicum.</li>
              </ul>
            </div>
            <div className="resume-item">
              <h4>CV. Perdana Teknik</h4>
              <h5>System Administrator</h5> <h6>June 2018 to July 2018</h6>
              <p>
                <em>Cilegon, Banten, ID </em>
              </p>
              <ul>
                <li>Bookkeeping of stock data on CV. Perdana Teknik</li>
                <li>
                  Handle application of a sales system with the Moka application
                </li>
                <li>
                  Handle application of an accounting system with a system using
                  the Zahir application
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
            <h3 className="resume-title">Work Experience</h3>
            <div className="resume-item">
              <h4>PT. Artha Infotama</h4>
              <h5>IT Support Consultant - Staff</h5>{" "}
              <h6>June 2022 - June 2024</h6>
              <p>
                <em>Central Jakarta, DKI Jakarta, ID </em>
              </p>
              <ul>
                <li>
                  Collaborated with operations and technical teams to define
                  system requirements and prepare standard operating procedures.
                </li>
                <li>
                  Communicated effectively with product development
                  stakeholders.
                </li>
                <li>
                  Conducted testing and validation of developed features or
                  functions.
                </li>
                <li>
                  Created and maintained documentation, including UAT scripts
                  and test results.
                </li>
                <li>
                  Managed tickets in CRM, addressing client needs and
                  complaints.
                </li>
                <li>Prepared weekly CRM status reports for stakeholders.</li>
                <li>
                  Led project development and implementation in ERP systems.
                </li>
                <li>
                  Created and maintained documentation, including UAT scripts
                  and test results.
                </li>
                <li>Conducted query optimization for stored procedures.</li>
                <li>Provided onsite support to clients as needed.</li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>PT. Samudra Kreatif Studio</h4>
              <h5>Research, Support, & Development - Staff</h5>{" "}
              <h6>June 2019 - June 2020</h6>
              <p>
                <em>Cianjur, West Java, ID</em>
              </p>
              <ul>
                <li>Making controller game with bicycle system</li>
                <li>
                  Build and maintain a network connection troughout the office
                </li>
                <li>Creating a Racing Sim Controller</li>
                <li>
                  Creating a game controller system with a temperature sensor
                  (MLX90614), pulse hear rate, force-sensing resistor, and a
                  galvanic skin response (GSR) based on Unity Engine integration
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
