import React from "react";
import Header from "./components/header";
import Hero from "./components/hero";
import Footer from "./components/footer";
import About from "./views/about";
import Resume from "./views/resume";
import Portofolio from "./views/portofolio";
import Contact from "./views/contact";
import "./App.css";
import { Helmet } from "react-helmet";
import "./assets/js/aos";
import "glightbox/dist/css/glightbox.min.css";
import ScrollToTop from "react-scroll-to-top";
import MyImage from "./assets/img/top-arrow.png";
// import { Bootstrap, BootstrapIcons } from "./assets/vendor";

function App() {
  return (
    <div className="App">
      <Helmet>
        <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css"
        />
        <script src="https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"></script>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
      </Helmet>
      <ScrollToTop
        smooth
        component={<img src={MyImage} width="28" height="28" alt="Top Arrow" />}
      />
      <Header />
      <Hero />
      <About />
      <Resume />
      <Portofolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
